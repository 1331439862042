import styles from "styles/Index.module.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import React, { FC, ReactNode, SyntheticEvent, useEffect, useMemo } from "react";
import { SSRConfig, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import i18nConfig from "next-i18next.config";

import { Button } from "components/shared/Button/Button";
import PostItem from "components/blog/PostItem/PostItem";
import IconArrowRight from "public/img/icon-arrow-right.svg";

import Head from "next/head";
import AccordionBlock from "/components/main/AccordionBlock";
import InfiniteScroll from "components/shared/InfiniteScroll/InfiniteScroll";
import { mediaList, IMedia } from "utils/constants";
import BannerSwiper from "/components/main/banners/BannerSwiper";
import SupportCoinsBlock from "components/main/SupportCoinsBlock/index";
import FeaturesBlock from "components/main/FeaturesBlock";
import PluginsBlock from "components/plugins/PluginsBlock";
import AcceptPaymentsStepBlock from "components/main/AcceptPaymentsStepBlock";
import StartAcceptingBlock from "/components/main/StartAcceptingBlock";
import { getLocaleLink } from "../../utils/getLocaleLink";

interface IMedia {
  url: string;
  img: string;
}

const ResponsiveSwiper: FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div className={styles.swiper_responsive}>
        <Swiper
          key={i18n.language}
          dir={i18n.dir()}
          className={styles.swiper_list}
          direction="horizontal"
          allowTouchMove={true}
          breakpoints={{
            768: {
              spaceBetween: 24,
              slidesPerView: 3,
            },
            425: {
              spaceBetween: 24,
              slidesPerView: 1.1,
            },
            0: {
              spaceBetween: 16,
              slidesPerView: 3,
            },
          }}
        >
          {children}
        </Swiper>
        <div className={styles.swiper_list}>{children}</div>
      </div>
    </>
  );
};

export interface IPost {
  id: string;
  alias: string;
  meta_title: string;
  meta_description: string;
  title: string;
  comments_count: number;
  cover: string;
  description: string;
  tags: {
    id: string;
    name: string;
  }[];
  created_at: string;
}

interface IProps extends SSRConfig {
  commentsCountList: { post_id: string; count: number }[];
  postList: IPost[];
}

export default function LandingConcept(props: IProps): JSX.Element {
  const { t, i18n } = useTranslation(["common", "roadmap", "blog"]);
  const postList = props.postList;

  function smoothScroll(e: SyntheticEvent) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  }

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", smoothScroll);
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener("click", smoothScroll);
      });
    };
  }, []);

  const moreCMS = (classname: string) => (
    <Link href="/plugins" className={cn(styles.cms_link, styles[classname])}>
      <p>{t("More CMS")}</p>
      <IconArrowRight />
    </Link>
  );

  const getStartedBtnWidth = useMemo(
    () => (["uk", "kk"].some((lang) => lang === i18n?.language) ? "200px" : "185px"),
    [i18n],
  );

  const getMediaList = (): IMedia[] => {
    if (i18n.language === "ru")
      return mediaList.map((item) => {
        if (item?.url?.includes("bestchange"))
          return { ...item, url: item?.url?.replace(".com", ".ru") };

        return item;
      });

    return mediaList;
  };

  return (
    <>
      <Head>
        {/* BASE */}
        <title>{t("Crypto Payment Gateway You Can Rely On")}</title>
        <meta name="description" content={t("Crypto Payment Solution For Business")} />
        {/* Open Graph */}
        <meta property="og:title" content={t("Crypto Payment Gateway You Can Rely On")} />
        <meta property="og:description" content={t("Crypto Payment Solution For Business")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={"https://cryptomus.com/gateway"} />
        <meta property="og:image" content={"https://storage.cryptomus.com/commercial/index.png"} />
        <meta property="og:image:alt" content={t("Crypto Payment Gateway You Can Rely On")} />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("Crypto Payment Gateway You Can Rely On")} />
        <meta name="twitter:description" content={t("Crypto Payment Solution For Business")} />
        {/* URL */}
        <link
          rel="canonical"
          href={`https://cryptomus.com${props?.locale === "en" ? "" : `/${props?.locale}`}/gateway`}
        />
        <link href="/api/feed.xml" rel="alternate" type="application/atom+xml" />
        <link rel="alternate" hrefLang="en" href="https://cryptomus.com/gateway" />
        <link rel="alternate" hrefLang="es" href="https://cryptomus.com/es/gateway" />
        <link rel="alternate" hrefLang="de" href="https://cryptomus.com/de/gateway" />
        <link rel="alternate" hrefLang="fr" href="https://cryptomus.com/fr/gateway" />
        <link rel="alternate" hrefLang="tr" href="https://cryptomus.com/tr/gateway" />
        <link rel="alternate" hrefLang="ru" href="https://cryptomus.com/ru/gateway" />
        <link rel="alternate" hrefLang="uk" href="https://cryptomus.com/uk/gateway" />
        <link rel="alternate" hrefLang="uz" href="https://cryptomus.com/uz/gateway" />
        <link rel="alternate" hrefLang="kk" href="https://cryptomus.com/kk/gateway" />
        <link rel="alternate" hrefLang="pl" href="https://cryptomus.com/pl/gateway" />
        <link rel="alternate" hrefLang="ar" href="https://cryptomus.com/ar/gateway" />
        <link rel="alternate" hrefLang="ko" href="https://cryptomus.com/ko/gateway" />
        <link rel="alternate" hrefLang="ja" href="https://cryptomus.com/ja/gateway" />
        <link rel="alternate" hrefLang="pt" href="https://cryptomus.com/pt/gateway" />
        <link rel="alternate" hrefLang="fa" href="https://cryptomus.com/fa/gateway" />
        <link rel="alternate" hrefLang="pa" href="https://cryptomus.com/pa/gateway" />
      </Head>
      <div>
        <section className={cn(styles.top_container)}>
          <div className={styles.top__content}>
            <div className={styles.top__titles}>
              <span className={styles.top__subtitle}>{t("Accept Crypto Payments")}</span>
              <h1>{t("Cryptomus Crypto Payment Gateway")}</h1>
              <Button
                white
                size="m"
                linkProps={{
                  type: "external",
                  href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
                }}
                className={styles.btn}
                width={getStartedBtnWidth}
              >
                {t("Get Started").toUpperCase()}
              </Button>
            </div>
            <BannerSwiper />
          </div>
        </section>
        <div className={cn(styles.container, styles.media_container)}>
          <div className={styles.media}>
            <div className={styles.infinite_container}>
              <InfiniteScroll
                items={getMediaList()}
                renderItem={(item: IMedia) => (
                  <a
                    key={item.img}
                    target="_blank"
                    href={item.url}
                    className={cn(styles.infinite_item)}
                    aria-label={"Read morea about coin"}
                  >
                    <img
                      src={`/img/press-${item.img}`}
                      alt={item.img}
                      height={item?.url?.includes("bestchange") ? 40 : undefined}
                    />
                  </a>
                )}
              />
            </div>
          </div>
        </div>
        <section className={cn(styles.container, styles.accordion__container)}>
          <AccordionBlock />
        </section>
        <section className={cn(styles.container, styles.features__container)}>
          <FeaturesBlock />
        </section>
        <section className={cn(styles.container, styles.support__coins__container)}>
          <SupportCoinsBlock />
        </section>
        <section className={cn(styles.container, styles.accept__steps__container)}>
          <AcceptPaymentsStepBlock />
        </section>
        <section className={cn(styles.container, styles.plugins__container)}>
          <PluginsBlock />
        </section>
        <div className={cn(styles.container, styles.blog_container)}>
          <div className={styles.blog}>
            <h3>{t("Blog")}</h3>
            <ResponsiveSwiper>
              {postList?.slice(0, 3).map((post) => (
                <SwiperSlide className={styles.swiper_item_container} key={post.cover}>
                  <PostItem
                    data={post}
                    isLoading={postList.loading}
                    commentsCountList={props.commentsCountList}
                  />
                </SwiperSlide>
              ))}
            </ResponsiveSwiper>
          </div>
        </div>
        <section className={cn(styles.container, styles.start__accepting__container)}>
          <StartAcceptingBlock />
        </section>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  const res = await fetch(`https://api-app.cryptomus.com/v1/blog/post/list?locale=${locale}`);
  const data = await res.json();

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "roadmap", "blog"], i18nConfig)),
      postList: data.result.items,
      locale: locale,
    },
  };
}
